import { configureStore } from '@reduxjs/toolkit'
import cartSlice  from '../slices/cartSlice'
import userSlice  from '../slices/userSlice'

export const store = configureStore({
  reducer: {
    cartSlice: cartSlice,
    userSlice: userSlice
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch