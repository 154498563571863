import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface UserState {
  user: any
}

const initialState: UserState = {
    user: null
}

export const counterSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    addToUser: (state: any, action: PayloadAction<any>) => {
      state.user = action.payload
    }
  },
})

export const { addToUser } = counterSlice.actions

export default counterSlice.reducer