import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface CartState {
  cart: Array<any>
}

const initialState: CartState = {
    cart: []
}

export const counterSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state: any, action: PayloadAction<Array<any>>) => {
      state.cart = action.payload
    }
  },
})

export const { addToCart } = counterSlice.actions

export default counterSlice.reducer